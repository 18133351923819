import { IVideoPlayer } from "interfaces/VideoStreamingInterface";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";
import Loading from "components/loading/loading.component";

const VideoPlayer: React.FC<IVideoPlayer> = ({
  video_url,
  thumbnail,
  className,
  videoStatus,
  onStart,
  onEnd,
  onProgress,
  onPaused,
  onSeek,
  onError,
  videoRef,
  onBuffer
}) => {
  const [videoPlayerStatus, setVideoPlayerStatus] = useState<{
    playing: boolean;
    visible: boolean;
    alreadyStarted: boolean;
    buffering?: boolean;
    preloaded?: boolean;
  }>({
    playing: false,
    visible: false,
    alreadyStarted: false,
    buffering: false,
    preloaded: false,
  });

  const [buffered, setBuffered] = useState(0);  // Store buffered percentage
  const [played, setPlayed] = useState(0);  // Store played percentage
  const [totalDuration, setTotalDuration] = useState(0); // Store total video duration
 // Ref for ReactPlayer instance
  const seekBarRef = useRef<HTMLDivElement>(null); // Ref for custom seek bar

  useEffect(() => {
    if (video_url) {
      setVideoPlayerStatus({
        playing: false,
        visible: false,
        alreadyStarted: false,
        buffering: false,
        preloaded: false,
      });
    }
  }, [video_url]);

  const handleProgress = (state: { played: number, playedSeconds: number, loaded: number, loadedSeconds: number }) => {
    if (state.loadedSeconds >= 10 && !videoPlayerStatus.preloaded) {
      setVideoPlayerStatus((prevData) => ({ ...prevData, buffering: false, preloaded: true,playing:true }));
    }

    setPlayed((state.playedSeconds * 100) / totalDuration); // Set played percentage
    setBuffered((state.loadedSeconds * 100) / totalDuration); // Set buffered percentage
    onProgress(state);
  };

  const handlePlayPauseClick = () => {
    // Toggle playing state using custom button
    setVideoPlayerStatus((prevData) => ({
      ...prevData,
      playing: !prevData.playing,
      alreadyStarted: true,
      buffering: prevData.playing ? false : true, // Start buffering when play is clicked
    }));
  };

  const handleReady = () => {
    setVideoPlayerStatus((prevData) => ({
      ...prevData,
      buffering: true,
    }));
  };

  const handlePlay = () => {
    setVideoPlayerStatus((prevData) => ({
      ...prevData,
      buffering: false,
      playing: true,
    }));
    // onStart();
  };

  const handlePause = () => {
    setVideoPlayerStatus((prevData) => ({
      ...prevData,
      playing: false,
    }));
    // onPaused();
  };

  const handleSeekBarClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!seekBarRef.current || !videoRef.current) return;

    const rect = seekBarRef.current.getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const newPlayedPercentage = (clickX / rect.width) * 100;
    const newTime = (newPlayedPercentage / 100) * totalDuration;

    videoRef.current.seekTo(newTime, "seconds");
    setPlayed(newPlayedPercentage);
    onSeek?.(newTime);
  };




  return (
    <div className="relative" key={videoStatus}>
      {/* Show loading only when buffering after play */}
      {videoPlayerStatus.buffering && (
        <div className="absolute inset-0 flex justify-center items-center">
          <div className="loader" style={{ color: 'white' }}><Loading /></div> {/* Buffering Indicator */}
        </div>
      )}

      <ReactPlayer
        ref={videoRef}
        url={video_url}
        className={`flex justify-center !rounded-lg ${className} w-auto h-auto`}
        config={{ file: { attributes: { controlsList: "nodownload", preload: 'auto', disablePictureInPicture: true } } }}
        light={(!videoPlayerStatus.playing && !videoPlayerStatus.alreadyStarted) && thumbnail as string}
        playing={videoPlayerStatus.playing}
        controls={videoStatus === 'completed' ? true : false}
        width={"253px"}
        height={"450px"}
        onReady={handleReady}
        pip={false}
        onEnded={() => {
          setVideoPlayerStatus({ playing: false, visible: false, alreadyStarted: true, buffering: false });
          onEnd();
        }}
        onDuration={setTotalDuration}  // Set total duration
        onBuffer={() => {
          setVideoPlayerStatus((prevData) => ({ ...prevData, buffering: true }));
          onBuffer();
        }}
        onBufferEnd={() => {
          setVideoPlayerStatus((prevData) => ({ ...prevData, buffering: false }));
        }}
        onProgress={handleProgress}
        onPlay={handlePlay}  // Synchronize with internal play event
        onPause={handlePause}  // Synchronize with internal pause event
        onError={onError}
        onSeek={onSeek}
        
        
      />

      {/* Custom Seekbar */}
      <div
        className="relative mt-2 h-2 w-full bg-gray-300 rounded cursor-pointer"
        ref={seekBarRef}
        onClick={handleSeekBarClick}
      >
        {/* Buffer progress */}
        <div
          className="absolute top-0 left-0 h-2 bg-slate-300 rounded-lg"
          style={{ width: `${buffered}%` }} // Width as buffered percentage
        ></div>

        {/* Playback progress */}
        <div
          className="absolute top-0 left-0 h-2 bg-current rounded-lg"
          style={{ width: `${played}%` }} // Width as played percentage
        ></div>
      </div>

      {/* Custom Play/Pause Button */}
      {videoStatus !== "completed" && (
        <div className="absolute flex items-center bottom-[5px] w-full justify-between p-4">
          <div className="cursor-pointer" onClick={handlePlayPauseClick}>
            {videoPlayerStatus.playing ? (
              <svg fill="white" width="15px" height="15px" viewBox="0 0 300 300" className="drop-shadow-lg">
                <path d="M14.22,45.665v186.013c0,25.223,16.711,45.66,37.327,45.66c20.618,0,37.339-20.438,37.339-45.66V45.665 c0-25.211-16.721-45.657-37.339-45.657C30.931,0,14.22,20.454,14.22,45.665z" />
                <path d="M225.78,0c-20.614,0-37.325,20.446-37.325,45.657V231.67c0,25.223,16.711,45.652,37.325,45.652s37.338-20.43,37.338-45.652 V45.665C263.109,20.454,246.394,0,225.78,0z" />
              </svg>
            ) : (
              <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="drop-shadow-lg">
                <path d="M15 9.12646L1.44431e-05 17.7867L1.52002e-05 0.46621L15 9.12646Z" fill="white" />
              </svg>
            )}
          </div>

          {/* Restart Button */}
          <div className="cursor-pointer" onClick={() => videoRef.current?.seekTo(0)}>
            <svg width={16} height={22} viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="drop-shadow-lg">
              <g clipPath="url(#clip0_121_3299)">
                <path d="M2.28129 16.5133C3.51839 17.8416 5.1934 18.5732 6.99805 18.5732C10.568 18.5732 13.4724 15.639 13.4724 12.0326C13.4724 8.4263 10.5679 5.49212 6.99805 5.49212C6.05178 5.49212 5.13421 5.69742 4.29675 6.08615L6.62541 6.73888C7.298 6.92737 7.69201 7.63105 7.50533 8.31052C7.35004 8.87582 6.84186 9.24613 6.28817 9.24613C6.17632 9.24613 6.0626 9.23113 5.9495 9.19952L0.925988 7.79145C0.603145 7.70095 0.328938 7.48446 0.163807 7.18975C-0.00105667 6.89504 -0.0435238 6.54614 0.0460606 6.21973L1.4626 1.06235C1.64919 0.382883 2.34575 -0.0151517 3.01834 0.173434C3.69094 0.361929 4.08495 1.0656 3.89827 1.74507L3.35737 3.7142C4.49076 3.20751 5.72536 2.93872 6.99796 2.93872C11.9618 2.93872 16 7.01814 16 12.0326C16 17.0471 11.9618 21.1266 6.99805 21.1266C4.52554 21.1266 2.1352 20.0827 0.440428 18.2627C-0.0378914 17.7493 -0.013485 16.9412 0.494965 16.4582C1.00323 15.975 1.80341 15.9993 2.28129 16.5133Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_121_3299)">
                  <rect width={16} height={21} fill="white" transform="matrix(-1 0 0 1 16 0.126465)" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
